<template>
    <!-- 推荐有奖 -->
    <div class="award">
        <!-- 内容一 -->
        <div class="awData1">
            <!-- 大图片 -->
            <div class="ad1Img" v-if="getAdvData(9).children">
                <div class="aiBox">
                    <div class="txt">
                        <h2>威有云推广大使邀新奖励</h2>
                        <p>推荐好友上云最高获8万/月现金，推广大使仅限个人实名认证用户参与</p>
                    </div>
                    <div class="aiBtn">
                        <a :href="getAdvData(9).children[0].url" target="_blank" rel="noopener noreferrer">
                            我要推广
                            <div class="adbImg">
                                <img class="aiImg" :src="getWebData().public_phone" alt="" />
                            </div>
                        </a>
                    </div>
                    <img :src="getAdvData(9).children[0].image" />
                </div>
                <div class="imgs">
                    <div>
                        <img :src="getAdvData(9).children[0].children.find(e => e.id == 239).image" alt="" />
                        <img :src="getAdvData(9).children[0].children.find(e => e.id == 240).image" alt="" />
                        <img :src="getAdvData(9).children[0].children.find(e => e.id == 241).image" alt="" />
                    </div>
                </div>
            </div>
            <!-- 图文 -->
            <div class="ad1Texts">
                <div class="atTop">
                    <p class="alP">{{ topRich.title }}</p>
                    <div class="editor-p" v-html="topRich.details"></div>
                </div>
                <div class="atBottom" v-if="atBottom.length">
                    <div v-for="el in atBottom" :key="el.id">
                        <p><span>邀请</span> - {{ el.title }}</p>
                        <p>{{ el.intro }}</p>
                        <p></p>
                        <button>
                            <a :href="el.url" @click="e => (el.url ? '' : e.preventDefault())" target="_blank">立即邀请</a>
                            <span :style="`background-image: url(${getWebData().public_phone});`"></span>
                        </button>
                        <img :src="el.image" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 内容二 -->
        <div class="awData2">
            <!-- 标题 -->
            <div class="ad2Top">
                <div class="ad2Img">
                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <p class="ad2P">推荐流程</p>
                <p class="ad2P2">无需注册，轻松推荐</p>
            </div>
            <!-- 4个盒子 -->
            <div class="ad2Lists">
                <ul>
                    <li class="ad2Li">
                        <div class="ad2Icon">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="a2iImg" />
                        </div>
                        <div class="ad2Num"></div>
                        <div class="ad2Text">
                            <p class="atBig">加入合伙人</p>
                            <p class="atSmall">只需手机验证，即刻成为合伙人</p>
                        </div>
                    </li>
                    <li class="ad2Li">
                        <div class="ad2Icon">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="a2iImg2" />
                        </div>
                        <div class="ad2Num"></div>
                        <div class="ad2Text">
                            <p class="atBig">分享链接/提交线索</p>
                            <p class="atSmall">前往表单后台页面获取专属链接，或直接提交好友信息</p>
                        </div>
                    </li>
                    <li class="ad2Li">
                        <div class="ad2Icon">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="a2iImg3" />
                        </div>
                        <div class="ad2Num"></div>

                        <div class="ad2Text">
                            <p class="atBig">新用户下单</p>
                            <p class="atSmall">从新用户提交信息日起，180天内成交即刻返现奖励</p>
                        </div>
                    </li>
                    <li class="ad2Li">
                        <div class="ad2Icon">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="a2iImg4" />
                        </div>
                        <div class="ad2Num"></div>

                        <div class="ad2Text">
                            <p class="atBig">奖励发放</p>
                            <p class="atSmall">推荐成功，10个工作日内发放奖励</p>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 两个按钮 -->
            <div class="ad2Btns">
                <div class="at2Btn">
                    立即推荐
                    <div class="adbImg">
                        <img class="aiImg" :src="getWebData().public_phone" alt="" />
                    </div>
                </div>
                <div class="at2Btn">
                    扫码咨询
                    <div class="adbImg">
                        <img class="aiImg" :src="getWebData().public_phone" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 内容三 -->
        <div class="awData3">
            <!-- 标题 -->
            <div class="ad3Top">
                <div class="alImg">
                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <p class="ad3P">热门推荐产品</p>
                <p class="ad3P2">威有全系列产品均参与活动</p>
            </div>
            <!-- 6个盒子 -->
            <div class="ad3List">
                <ul>
                    <li class="ad3Li">
                        <div class="ad3Icon">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="aiImg" />
                        </div>
                        <div class="ad3Text">
                            <div class="atBig">在线客服</div>
                            <div class="atSmall">
                                全渠道接入，一体化工作台，洞察客户信息及行为轨迹， 智能分配，提供精准、个性化服务
                            </div>
                        </div>
                    </li>
                    <li class="ad3Li">
                        <div class="ad3Icon">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="aiImg2" />
                        </div>
                        <div class="ad3Text">
                            <div class="atBig">云呼叫中心</div>
                            <div class="atSmall">1小时接入，满足呼入+呼出双场景，智能外显当地手机号码资源</div>
                        </div>
                    </li>
                    <li class="ad3Li">
                        <div class="ad3Icon">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="aiImg3" />
                        </div>
                        <div class="ad3Text">
                            <div class="atBig">客服机器人</div>
                            <div class="atSmall">预测用户意图，引导用户自助解决问题，机器人应答准确率高达97%</div>
                        </div>
                    </li>
                    <li class="ad3Li">
                        <div class="ad3Icon">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="aiImg4" />
                        </div>
                        <div class="ad3Text">
                            <div class="atBig">视频客服</div>
                            <div class="atSmall">
                                多渠道、面对面的实时在线视频，文字聊天+视频模式，高清稳定通话，提升远程服务沟通效率
                            </div>
                        </div>
                    </li>
                    <li class="ad3Li">
                        <div class="ad3Icon">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="aiImg5" />
                        </div>
                        <div class="ad3Text">
                            <div class="atBig">工单系统</div>
                            <div class="atSmall">支持多渠道、多角色、多场景、跨平台创建工单，灵活高效流转，增强跨部门协作</div>
                        </div>
                    </li>
                    <li class="ad3Li">
                        <div class="ad3Icon">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" class="aiImg6" />
                        </div>
                        <div class="ad3Text">
                            <div class="atBig">智能质检</div>
                            <div class="atSmall">智能解析文本、语言对话，内置10+行业质检规则，高效质检100%全量服务</div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 底部文字 -->
            <div class="ad3Bottom">
                <div class="abLeft">
                    <p class="ablBig">扫码·咨询&直达推荐</p>
                    <p class="ablSmall">扫码添加威有推荐奖励计划活动小助手， 了解活动相关所有信息，也可直接向小助手推荐好友</p>
                </div>
                <div class="abRight">
                    <img :src="getWebData().wechat_visitor" alt="" class="abrImg" />
                    <p class="abrP">微信扫一扫</p>
                </div>
            </div>
        </div>
        <!-- 内容四 -->
        <div class="awData4">
            <!-- 标题 -->
            <div class="ad4Top">
                <div class="ad2Img">
                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <p class="ad4P">客户案例</p>
                <p class="ad4P2">已服务零售电商、汽车、医疗、企业服务等领域40万+企业，放心推荐</p>
            </div>
            <!-- 图片 -->
            <div class="ad4Bottom">
                <ul>
                    <li class="a4bLi" v-for="item in anli" :key="item.id" @click="goDetail(item)">
                        <img v-if="item.logo" :src="item.logo" />
                        <span v-else>{{ item.title }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <!-- 内容五 -->
        <div class="awData5">
            <!-- 标题 -->
            <div class="ad5Top">
                <div class="alImg">
                    <img src="http://iv.okvu.cn/vugw/img/vu818.png" alt="" />
                </div>
                <p class="ad5P">{{ bottomRich.title }}</p>
            </div>
            <!-- 文字 -->
            <div class="ad5Texts">
                <div class="editor-p" v-html="bottomRich.details"></div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Award",
    inject: ["getWebData", "getAdvData"],
    data() {
        return {
            topRich: {},
            bottomRich: {},
            atBottom: [],
            anli: [],
        };
    },
    created() {
        // 富文本
        axios.get("/api/recommend/details").then(({ data }) => {
            this.topRich = data[0] ? data[0] : {};
            this.bottomRich = data[1] ? data[1] : {};
        });
        // 立即邀请
        axios.get("/api/recommend/show").then(({ data }) => {
            if (data.length > 3) data = data.slice(0, 3);
            this.atBottom = data;
        });
        // 客服案例
        axios.get("/api/project/recommend").then(({ data }) => {
            let arr = [];
            if (data.length > 36) {
                function sj(range) {
                    // 0 ~ range随机数
                    let num = Math.floor(Math.random() * range);
                    if (arr.findIndex(e => e == num) != -1) return sj(range);
                    return num;
                }
                for (let i = 0; i < 36; i++) {
                    arr.push(sj(data.length));
                }
                arr.forEach((e, i) => {
                    arr[i] = data[e];
                });
            } else {
                arr = data;
            }
            axios.get("/api/project/recommendid?id=" + arr.join(",")).then(({ data }) => {
                this.anli = data;
            });
        });
    },
    methods: {
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            this.$router.push({
                name: "listDetail",
                params: { id },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.award {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    // 内容一
    .awData1 {
        width: 100%;
        height: 1200px;
        // 大图片
        .ad1Img {
            // max-width: 1920px;
            height: 532px;
            margin: 0 auto;
            position: relative;
            background: url(https://iv.okvu.cn/vugw/img/vu818_3d_1.png) no-repeat center/cover;
            background-size: auto 100%;
            // overflow: hidden;
            .aiBox {
                max-width: 1200px;
                height: 100%;
                margin: 0 auto;
                position: relative;
                & > img:nth-of-type(1) {
                    position: absolute;
                    width: 580px;
                    height: 500px;
                    right: 0px;
                    bottom: -80px;
                    z-index: 8;
                }

                .txt {
                    text-align: left;
                    position: absolute;
                    left: 0px;
                    top: 30%;
                    z-index: 9;
                    h2 {
                        font-size: 42px;
                        font-weight: 500;
                        margin-bottom: 10px;
                    }
                }
            }
            .imgs {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;
                overflow: hidden;
                & > div {
                    max-width: 1200px;
                    height: 100%;
                    margin: 0 auto;
                    position: relative;
                    & > img:nth-of-type(1) {
                        position: absolute;
                        width: 810px;
                        height: 810px;
                        left: -533px;
                        bottom: -461px;
                        animation: an1 10s infinite;
                        @keyframes an1 {
                            0% {
                                transform: translateY(0px);
                            }
                            50% {
                                transform: translateY(-40px);
                            }
                            100% {
                                transform: translateY(0px);
                            }
                        }
                    }
                    & > img:nth-of-type(2) {
                        position: absolute;
                        width: 711px;
                        height: 711px;
                        right: -415px;
                        bottom: -327px;
                        animation: an2 8s infinite;
                        @keyframes an2 {
                            0% {
                                transform: translateY(0px);
                            }
                            50% {
                                transform: translateY(-40px);
                            }
                            100% {
                                transform: translateY(0px);
                            }
                        }
                    }

                    & > img:nth-of-type(3) {
                        width: 1118px;
                        height: 1118px;
                        position: absolute;
                        left: 0%;
                        top: -600px;
                        animation: an3 12s infinite;
                        @keyframes an3 {
                            0% {
                                transform: translateX(0px) translateY(0px);
                            }
                            50% {
                                transform: translateX(-40px) translateY(-40px);
                            }
                            100% {
                                transform: translateX(0px) translateY(0px);
                            }
                        }
                    }
                }
            }
            .aiImg {
                width: 100%;
                height: 100%;
            }
            .aiBtn {
                width: 182px;
                height: 54px;
                position: absolute;
                bottom: 100px;
                left: 0%;
                border-radius: 10px;
                text-align: center;
                line-height: 50px;
                font-size: 20px;
                cursor: pointer;
                color: white;
                background: linear-gradient(to right, #7781f1, #7781f1e0);
                z-index: 9;
                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                    color: white;
                }
                .adbImg {
                    width: 100px;
                    height: 100px;
                    position: absolute;
                    bottom: 65px;
                    right: 25%;
                    opacity: 0;
                    translate: 1s;
                    .aiImg {
                        width: 100%;
                        height: 100%;
                        border-radius: 20px;
                    }
                }
            }
            .aiBtn:hover {
                .adbImg {
                    opacity: 1;
                }
            }
        }
        // 图文
        .ad1Texts {
            width: 100%;
            height: 600px;
            margin-top: 100px;
            .atTop {
                width: 1280px;
                height: 350px;
                background-color: #fff;
                margin: 0 auto;

                .alP {
                    width: 100%;
                    text-align: left;
                    height: 50px;
                    line-height: 50px;
                    font-size: 35px;
                    font-weight: 900;
                    position: relative;
                }
            }
            .atBottom {
                width: 1280px;
                // height: 250px;
                margin: 0 auto;
                margin-top: 20px;
                position: relative;
                display: flex;
                justify-content: space-between;
                & > div {
                    width: 30%;
                    background: linear-gradient(to right, #fff, #f3f5f8);
                    box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, 0.1);
                    border-radius: 4px;
                    position: relative;
                    padding: 20px;
                    text-align: left;
                    font-size: 14px;
                    transition: 0.5s;
                    &:hover {
                        transform: translateY(-5px);
                        & > img {
                            width: 140px;
                        }
                    }
                    p {
                        line-height: 24px;
                        height: 24px;
                        a {
                            color: #7781f1;
                        }
                    }
                    & > p:nth-of-type(1) {
                        font-size: 18px;
                        span {
                            color: #7781f1;
                        }
                    }
                    & > img {
                        position: absolute;
                        right: 0px;
                        bottom: 0px;
                        width: 120px;
                        transition: 0.5s;
                    }
                    & > button {
                        background: #fff;
                        border: 1px solid #7781f1;
                        color: #7781f1;
                        box-shadow: 8px 8px 20px rgba(55, 99, 170, 0.1);
                        width: 120px;
                        height: 36px;
                        line-height: 36px;
                        margin-top: 20px;
                        cursor: pointer;
                        transition: 0.2s;
                        border-radius: 5px;
                        position: relative;
                        & > a {
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                        &:hover {
                            background: #7781f123;
                            & > span {
                                display: block;
                                position: absolute;
                                width: 100px;
                                height: 100px;
                                left: 10px;
                                top: -110px;
                                background-repeat: no-repeat;

                                background-size: 100% 100%;
                                border-radius: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    // 内容二
    .awData2 {
        width: 100%;
        height: 800px;
        background-color: #f2f5fa;
        // 标题
        .ad2Top {
            width: 1210px;
            height: 80px;
            margin: 0 auto;
            padding-top: 100px;
            position: relative;
            box-sizing: content-box;
            .ad2P {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 40px;
                font-weight: 900;
                position: relative;
            }
            .ad2P2 {
                width: 100%;
                height: 50px;
                line-height: 50px;
            }
            .ad2Img {
                width: 72px;
                height: 72px;
                border-radius: 50%;
                position: absolute;
                top: 60px;
                left: -40px;
                overflow: hidden;
                img {
                    position: absolute;
                    bottom: -1000px;
                    left: -180px;
                }
            }
        }
        // 四个盒子
        .ad2Lists {
            width: 1280px;
            height: 300px;
            margin: 0 auto;
            margin-top: 50px;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-around;
                .ad2Li {
                    width: 23%;
                    height: 100%;
                    background-color: white;
                    position: relative;
                    border-radius: 10px;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    transition: 0.5s;
                    text-align: left;
                    .ad2Icon {
                        width: 40px;
                        height: 40px;
                        position: absolute;
                        top: 70px;
                        left: 25px;
                        overflow: hidden;
                        border-radius: 50%;
                        img {
                            width: 900px;
                            height: 1232px;
                        }
                        .a2iImg {
                            position: absolute;
                            top: -105px;
                            left: -5px;
                        }
                        .a2iImg2 {
                            position: absolute;
                            top: -105px;
                            left: -56px;
                        }
                        .a2iImg3 {
                            position: absolute;
                            top: -106px;
                            left: -106px;
                        }
                        .a2iImg4 {
                            position: absolute;
                            top: -108px;
                            left: -155px;
                        }
                    }
                    .ad2Num {
                        min-width: 45px;
                        height: 68px;
                        position: absolute;
                        top: 40px;
                        right: 40px;
                        background: url(http://iv.okvu.cn/vugw/img/vu818.png) no-repeat;
                        background-size: 1000px;
                    }
                    &:nth-child(1) .ad2Num {
                        background-position: -685px -88px;
                    }
                    &:nth-child(2) .ad2Num {
                        background-position: -737px -88px;
                    }
                    &:nth-child(3) .ad2Num {
                        background-position: -798px -88px;
                    }
                    &:nth-child(4) .ad2Num {
                        background-position: -856px -88px;
                    }
                    .ad2Text {
                        width: 80%;
                        height: 150px;
                        margin: 0 auto;
                        margin-top: 130px;
                        .atBig {
                            width: 100%;
                            height: 50px;
                            line-height: 50px;
                            font-size: 27px;
                            font-weight: 900;
                        }
                        .atSmall {
                            width: 80%;
                            height: 100px;
                            line-height: 25px;
                        }
                    }
                }
                .ad2Li:hover {
                    transform: translateY(-10px);
                    box-shadow: 1px 1px 10px 1px gray;
                }
            }
        }
        // 两个按钮
        .ad2Btns {
            width: 30%;
            height: 80px;
            margin: 0 auto;
            margin-top: 100px;
            position: relative;
            display: flex;
            justify-content: space-around;
            .at2Btn {
                width: 150px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                margin-top: 15px;
                border-radius: 10px;
                font-size: 20px;
                position: relative;
                cursor: pointer;
                .adbImg {
                    width: 100px;
                    height: 100px;
                    position: absolute;
                    bottom: 60px;
                    right: 15%;
                    opacity: 0;
                    translate: 1s;
                    .aiImg {
                        width: 100%;
                        height: 100%;
                        border-radius: 20px;
                    }
                }
            }
            .at2Btn:hover {
                .adbImg {
                    opacity: 1;
                }
            }
            .at2Btn:nth-child(1) {
                background: linear-gradient(to right, #7781f1, #7781f1e0);
                color: white;
            }
            .at2Btn:nth-child(2) {
                background-color: #fff;
                border: 1px solid #7781f1;
                color: #7781f1;
            }
        }
    }
    // 内容三
    .awData3 {
        width: 100%;
        height: 1000px;
        // 标题
        .ad3Top {
            width: 1210px;
            height: 80px;
            margin: 0 auto;
            padding-top: 100px;
            position: relative;
            box-sizing: content-box;
            .ad3P {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 40px;
                font-weight: 900;
                position: relative;
            }
            .ad3P2 {
                width: 100%;
                height: 50px;
                line-height: 50px;
            }
            .alImg {
                width: 72px;
                height: 72px;
                position: absolute;
                top: 60px;
                left: -40px;
                overflow: hidden;
                img {
                    position: absolute;
                    bottom: -1000px;
                    left: -5px;
                }
            }
        }
        // 6个盒子
        .ad3List {
            width: 1280px;
            height: 600px;
            margin: 0 auto;
            margin-top: 30px;
            ul {
                width: 100%;
                height: 100px;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                .ad3Li {
                    width: 32%;
                    height: 200px;
                    border-radius: 10px;
                    margin-top: 50px;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    transition: 0.5s;
                    text-align: left;
                    .ad3Icon {
                        width: 48px;
                        height: 45px;
                        float: left;
                        margin-left: 20px;
                        margin-top: 70px;
                        position: relative;
                        overflow: hidden;
                        img {
                            width: 800px;
                            height: 1200px;
                        }
                        .aiImg {
                            position: absolute;
                            top: -147px;
                            left: -102px;
                        }
                        .aiImg2 {
                            position: absolute;
                            top: -147px;
                            left: -203px;
                        }
                        .aiImg3 {
                            position: absolute;
                            top: -147px;
                            left: -253px;
                        }
                        .aiImg4 {
                            position: absolute;
                            top: -147px;
                            left: -152px;
                        }
                        .aiImg5 {
                            position: absolute;
                            top: -147px;
                            left: -51px;
                        }
                        .aiImg6 {
                            position: absolute;
                            top: -147px;
                            left: -1px;
                        }
                    }
                    .ad3Text {
                        width: 220px;
                        height: 150px;
                        float: left;
                        margin-left: 30px;
                        margin-top: 30px;
                        .atBig {
                            width: 100%;
                            height: 50px;
                            line-height: 50px;
                            font-size: 27px;
                            font-weight: 900;
                        }
                        .atSmall {
                            width: 100%;
                            height: 100px;
                            font-size: 15px;
                        }
                    }
                }
                .ad3Li:hover {
                    box-shadow: 1px 1px 10px 1px gray;
                    transform: translateY(-10px);
                }
            }
        }
        // 底部文字
        .ad3Bottom {
            width: 1280px;
            height: 150px;
            margin: 0 auto;
            .abLeft {
                width: 60%;
                height: 95px;
                float: left;
                text-align: left;
                .ablBig {
                    width: 100%;
                    height: 50px;
                    line-height: 50px;
                    font-size: 25px;
                    font-weight: 900;
                }
                .ablSmall {
                    width: 100%;
                    height: 45px;
                    line-height: 45px;
                }
            }
            .abRight {
                width: 200px;
                height: 150px;
                float: right;
                text-align: center;
                .abrImg {
                    width: 130px;
                    height: 120px;
                    border-radius: 5px;
                }
                .abrP {
                    width: 200px;
                    height: 30px;
                    line-height: 30px;
                }
            }
        }
    }
    // 内容四
    .awData4 {
        width: 100%;
        height: 900px;
        background-color: #f2f5fa;
        // 标题
        .ad4Top {
            width: 1210px;
            height: 80px;
            margin: 0 auto;
            padding-top: 100px;
            box-sizing: content-box;
            position: relative;
            .ad4P {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 40px;
                font-weight: 900;
                position: relative;
            }
            .ad4P2 {
                width: 100%;
                height: 50px;
                line-height: 50px;
            }
            .ad2Img {
                width: 72px;
                height: 72px;
                border-radius: 50%;
                position: absolute;
                top: 60px;
                left: -40px;
                overflow: hidden;
                img {
                    position: absolute;
                    bottom: -1000px;
                    left: -180px;
                }
            }
        }
        // 图片
        .ad4Bottom {
            // width: 1280px;
            height: 650px;
            margin: 0 auto;
            margin-top: 50px;
            padding: 0 20px;
            .ad4Img {
                width: 100%;
                height: 100%;
            }
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                .a4bLi {
                    // width: 190px;
                    width: calc(90% / 6);
                    height: 100px;
                    line-height: 100px;
                    text-align: center;
                    font-size: 20px;
                    background-color: white;
                    border-radius: 10px;
                    box-shadow: 1px 1px 10px 1px gainsboro;
                    overflow: hidden;
                    transition: 0.5s;
                    cursor: pointer;
                    img {
                        width: 40%;
                        height: 100%;
                        object-fit: contain;
                        transition: 0.5s;
                    }
                    // p {
                    //     // height: 100%;
                    //     // line-height: 100%;
                    // }
                }
                .a4bLi:hover {
                    box-shadow: 1px 1px 10px 1px gray;
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    // 内容五
    .awData5 {
        width: 100%;
        height: 700px;
        margin-top: 50px;
        // 标题
        .ad5Top {
            width: 1210px;
            height: 50px;
            margin: 0 auto;
            position: relative;
            .alImg {
                width: 72px;
                height: 72px;
                position: absolute;
                top: -40px;
                left: -40px;
                overflow: hidden;
                img {
                    position: absolute;
                    bottom: -1000px;
                    left: -5px;
                }
            }
            .ad5P {
                font-size: 40px;
                font-weight: 900;
                position: relative;
                text-align: left;
            }
        }
        // 文字
        .ad5Texts {
            width: 1280px;
            height: 550px;
            margin: 0 auto;
            margin-top: 50px;
            text-align: left;
        }
    }
}
</style>
